import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, slug, description, titleMedia, imageFb }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          siteUrl
        }
      }
    }
  `)

  const info = {
    description:
      "Druk cyfrowy wielkoformatowy i małoformatowy, usługi dodatkowe, materiały biurowe - Politechnika Gliwice Śląskie.",
    keyWords: [
      "druk",
      "drukarnia",
      "drukarnia gliwice",
      "drukarnia politechnika",
      "drukarnia gliwice politechnika",
      "cmyk",
      "druk wielkoformatowy",
      "ksero",
      "skanowanie",
      "materiały biurowe",
      "drukowanie",
      "plakaty",
      "ulotki",
      "wydruki",
      "zaproszenia",
      "projekty",
      "druk A4",
      "druk A3",
      "druk B1",
      "druk B2",
      "bindowanie",
      "laminowanie",
      "oprawa",
      "materiały papiernicze",
      "papier",
      "tektura introligatorska",
      "iks druk",
    ],
    titleMedia:
      "IKS druk - Druk cyfrowy wielkoformatowy i małoformatowy, usługi dodatkowe, materiały biurowe - Politechnika Gliwice Śląskie",
    //imageFb: data.datoCmsAsset.url,
    //twitterCreator: '', //twitterID
    //twitterUrl: '',
  }

  return (
    <Helmet>
      <html lang="pl" />
      <meta charset="utf-8" />
      <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
      <meta name="description" content={description || info.description} />
      <meta name="keywords" content={info.keyWords.join(", ")} />
      <meta name="author" content={data.site.siteMetadata.author} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/*GOOGLE*/}
      <meta name="google-site-verification" content="" />

      {/*FACEBOOK*/}
      <meta
        property="og:url"
        content={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleMedia || info.title} />
      <meta
        property="og:description"
        content={description || info.description}
      />
      <meta
        property="og:image"
        content="https://iksdruk.pl/static/d1442fa54b77bf2a89aaa48dbe6e13ea/b5380/praca.png"
      />

      <meta property="og:locale" content="pl_PL" />

      {/*TWITTER*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={info.twitterCreator} />
      <meta name="twitter:site" content={info.twitterUrl} />
      <meta name="twitter:title" content={titleMedia || info.titleMedia} />
      <meta
        name="twitter:description"
        content={description || info.description}
      />
      {/* <meta
                name="twitter:image"
                content={imageFb || info.imageFb} /> */}
    </Helmet>
  )
}
export default Seo
